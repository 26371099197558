/*======================================================
PRINT.CSS
======================================================

1. HIDDEN STYLES
- Homepage
- Interior
2. STRUCTURE
- Font
- Links
- Images
- Tables
- iCreate Classes
- Default Styles
- Breadcrumbs
3. DEFAULT PAGE SETUP
- Logo
- Top Nav
4. NEWS/BLOG
5. ACCORDIONS

===================================================== */

@media print {
	// Reset print styles for high contrast
	*,
	*:before,
	*:after {
		color: black !important;
		background: transparent !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}


	.flickity-slider {
		transform: none !important;
	}

	.flickity-viewport {
		height: auto !important;
	}

	.carousel__slide {
		all: unset !important;
	}

	.slide, .dateList_item {
		position: static !important;
	}

	.dateList_location{
		display: block;
		border-bottom: solid 1px;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}
	

	/*======================================================
	1. HIDDEN STYLES
	===================================================== */

	.toast, #calendar-popover-title, #skipContentWrapper, #topNav, .mobileNav, .searchContainer, #idLikeTo, .mainNavContainer, #mainNav, .mobileDropDown, .mobileSearchContainer, #quickLinksContainer, #footerNav a, .socialLinks, #embeddedEmailContainer, #EmergencyBannerWrapperOuter, #BrowserBannerWrapperOuter, #tableStyles, #languageContainer, #feedbackLink, #__ba_panel, .footerlogo, .footerNavMega, .footerBottomRight, #translateContainer, #atozBar, #socialWrapper.feedbackModal, .ui-dialog, .tableContainerHead, .tableContainer, .iframe-container-wrapper, #ytTranslate, header h1, .errorpage h1, .imageScrollerOuter, .slider-container, .mobileBannerText, .tickerContentOuter, #quickLinks, .mapLocator, #calendar, #quickLinks, #photoGalleries, .eSolutionsGroupPhotoGalleryV2PlaceholderDiv, #intBgImage, #subNavWrapper, #subNavContainer, #intBanner, .subNavTriggerContainer, .subNavTrigger, #actions, .toggleContent, #pageSubscription, .contrastSwitcher, .icEmergencyModal, .accordionButtons, #idLikeToSlideout, #idLikeToSlideoutClose, .dateList_image, .dateList_date, .lb-callToAction_link, .defaultButton-White, .bannerImage, .homepage-features-nav__img-wrapper, .bg, .flickity-controls, .flickity-button, #instagramFeeds{
		display: none;
	}

	#esol,
	.AccordionContent {
		display: block !important;
	}


	//How to
	.icHowto {
		margin-bottom: 40px;
		counter-reset: step;

		> h2 {
			margin-bottom: 5px;
			font-weight: bold;
		}

		.totalTimeRow {
			font-size: inherit;
			&:before {
				content: 'Total time: ';
				font-weight: bold;
			}
		}
		.toolsResourcesRow {
			font-size: inherit;
			&:before {
				content: 'Tools/resources: ';
				font-weight: bold;
			}
		}
		.stepRow {
			display: list-item;
			list-style-type: none;
			position: relative;
			margin-left: 50px;
	        page-break-inside: avoid;
			&:before {
				// content: attr(data-num);
				counter-increment: step;
				content: counter(step);
				content: counter(step) / "";    //alternate text for supported screen readers
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				font-size: em(20px);
				font-weight: bold;
				top: 2px;
				left: -50px;
				width: 32px;
				height: 32px;
				border: 3px solid #000;
				border-radius: 32px;
			}

			> div:nth-child(1) {
				margin-bottom: 10px;
				font-weight: bold;
			}
			> div:nth-child(2) {
				padding-bottom: 22px;
				margin-bottom: 18px;
			}
		}
	}

	/*======================================================
	2. STRUCTURE
	===================================================== */

	header {
		margin-bottom: 30px;
		padding: 20px 0 30px 0;
		border-bottom: solid 1px #999;
	}

	#logo {
		img {
			max-width: 234px;
			max-height: 52px; //If the logo is SVG it needs a width and height specified
		}

		.default {
			display: none;
		}

		.printLogo {
			display: block;
		}
	}


	footer {
		clear: both;
		margin-top: 30px;
		padding: 30px 0 20px 0;
		border-top: solid 1px #999;

		img {
			max-width: 200px;
		}
	}

	.footerTop {
		min-height: 0 !important;
	}

	#contactMainContainer {
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: solid 1px #ccc;
	}

	.box {
		margin: 10px 0;
	}

	.contactHeader {
		font-size: 18pt;
		line-height: 1.3em;
	}

	#mapWrapper {
		clear: both;
		margin-top: 30px;
		padding: 30px 0 20px 0;
		border-top: solid 1px #999;
	}

	.iframe-container {
		padding-bottom: 0 !important;
	}

	.lb-carousel {
		.flickity-viewport {
			height: auto !important;
		}

		.flickity-slider,
		.lb-carouselCell {
			position: static !important;
			transform: none !important;
		}

		.flickity-prev-next-button {
			display: none;
		}
	}

	.lb-imageBox_header, .lb-infoCard_contentWrapper, .lb-callToAction_contentWrapper {
		height: auto !important;
	}
	.siteMapSectionHeading {
		a,
		a:active,
		a:visited {
			color: #000 !important;
		}
	}

	/*======================================================
	3. DEFAULT PAGE SETUP
	===================================================== */

	#uber {
		margin: 0.5cm;
	}

	/* ====== FONT */

	body {
		color: #000;
		font-family: Verdana, "Helvetica Neue", Helvetica, Arial, Sans-Serif;
		font-size: 12pt;
		line-height: 1.3em;
		background: #FFF;
		min-height: 0 !important;
	}

	h1, h2, h3, h4, h5, h6, p {
		font-weight: normal;
		margin: 15px 0px;
	}

	h1 {
		font-size: 24pt;
		border-bottom: 1px solid #ccc;
		padding-bottom: 10px;
		margin-bottom: 30px;
	}

	h2,
	caption {
		font-size: 21pt;
	}

	h3 {
		font-size: 19pt;
	}

	h4 {
		font-size: 17pt;
	}

	h5 {
		font-size: 15pt;
	}

	h6 {
		font-size: 14pt;
	}

	caption {
		text-align: left;
		page-break-inside: avoid;
		page-break-after: auto;
		font-weight: normal;
		padding: 15px 0;
	}

	/* ====== LINKS */

	a {
		color: #000;
		text-decoration: underline;
		word-wrap: break-word;
	}

	#esol a:after,
	a#esol:after {
		content: " (" attr(href) ")";
		font-size: 70%;
	}

	/* ====== IMAGES */

	img { border: 0px; max-width: 500px; max-height: 200px; }
	.mapImage img { max-width: 80%; }

	img.ImageLeft, img.imageLeft, img.Left, .Left iframe {
		float: left;
		margin: 0 20px 20px 0;
		padding: 1px;
	}

	img.ImageRight, img.imageRight, img.Right, .Right iframe {
		float: right;
		margin: 0 0 20px 20px;
		padding: 1px;
	}

	img.Center {
		display: block;
		margin-left: auto;
		margin-right: auto;
		clear: both;
	}

	/* ====== TABLES */

	table, th, td {
		border-collapse: collapse;
		border: 1px solid #ccc;
	}

	table {
		table-layout: fixed;
		width: 100% !important;
		margin: 15px 0;
	}

	td {
		padding: 5px 10px;
	}

	th,
	.titlerow {
		font-weight: bold;

		p {
			font-weight: bold;
		}
	}

	/* ====== iCREATE CLASSES */

	.Center, .alignCenter {
		text-align: center;
	}

	.Left, .alignLeft {
		text-align: left;
	}

	.Right, .alignRight {
		text-align: right;
	}

	/* ====== DEFAULT STYLES */

	#contentLeft ol,
	#printArea ol {
		margin: 15px 0px 30px 30px;
	}

	#contentLeft ul,
	#printArea ul {
		margin: 15px 0px 30px 30px;
	}

	#contentLeft li,
	#printArea li {
		margin: 10px 0px;
	}

	video, audio, embed, object {
		display: none;
	}

	sub { font-size: 80%; }
	sup { font-size: 80%; }

	hr {
		display: block;
		border: none;
		height: 1px;
		color: #ccc;
		background-color: #ccc;
		margin: 20px 0;
	}

	/* ====== BREADCRUMBS */

	#breadcrumbs {
		font-size: em(16px);
		font-weight: normal;
		color: #000;
		margin: 0 0 30px 0;
		padding: 10px 0;
		border-top: 1px solid #eee;
		border-bottom: 1px solid #eee;

		a {
			text-decoration: none;

			&:after {
				display: inline-block;
				content: "/";
				margin-right: 10px;
				margin-left: 10px;
				text-decoration: none;
			}
		}
	}

	/*======================================================
	4. NEWS
	===================================================== */

	.backToSearchWrapper, .newsContainer_Months, #newsContainer_Pagelist, .newsItem_social, #blogLoginModal, #blogComments, .readmoreWrapper, #blogPagination, #blogNavWrapper, .newsSubscribeContainer img {
		display: none;
	}

	.newsItem_Header {
		clear: both;
		width: 100%;
	}

	.newsItem {
		display: block !important;
		padding: 0 0 10px 0;
		clear: both;
	}

	.newsCategoryTitle h1 {
		position: relative !important;
		top: auto !important;
		left: auto !important;
		padding-right: 0 !important;
		width: 100% !important;
	}

	.dateList_title{
		display: block;
	}

	/*======================================================
	5. ACCORDIONS
	===================================================== */

	.AccordionTrigger {
		border: 1px solid #ccc;
		padding: 0 15px;
		margin: 30px 0 15px 0;

		* {
			font-size: 12pt;
			font-weight: bold;
		}

		a {
			text-decoration: none;
		}
	}

	.AccordionContent {
		padding: 0 16px;
	}

	.Accordion{
		margin-bottom: 30px;
	}

	// News V3

	.blogFeed {
		.blogPostCategory,
		.blogPostTag {
			margin-top: 1rem;

			a {
				text-decoration: none;

				&:after {
					content: ', ';
				}

				&:last-of-type {
					&:after {
						content: '';
					}
				}
			}

			p {
				margin-top: 0;
			}
		}

		.blogPostCategory {
			&:before {
				content: 'Categories: ';
			}
		}

		.blogPostTag {
			&:before {
				content: 'Tags: ';
			}
		}
	}


	/*======================================================
	6. Layout Builder
	===================================================== */

	.fbg-row.lmRow.ui-sortable {
		display: flex;
		margin-bottom: 10px;
		flex-wrap: wrap;
		max-width: 100%;
	}

	.lb-callToAction_contentWrapper {
		height: auto!important;
		margin-bottom: 1em;
	}

	.lb-imageBox_header {
		height: auto!important;
	}

	.fbg-col-xs-12{
		display: block;
		width: 100%;
	}
	
}


